import React,{useState} from 'react';
import "./Login.css";


function Login(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!username || !password) {
            console.log('Please input username and password');
            return;
        }
        if (username === 'admin' && password === 'admin') {
            console.log('Login success');
            window.location.href = '/';
        }

        console.log(`Logging in with username: ${username} and password: ${password}`);
    }

    return (
        <div className='Login'>
            <form className='login-form' onSubmit={handleSubmit}>
                <h1>Login</h1>
                <input type="text" placeholder="please input your username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <input type="password" placeholder="please input your password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button>Login</button>
            </form>
        </div>
    )
}

export default Login;
